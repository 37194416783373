// Navigation1.tsx

import React, { FunctionComponent, useState } from "react";
import styles from "./Navigation1.module.css";

const Navigation1: FunctionComponent = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openCalendly = () => {
    window.Calendly.showPopupWidget('https://calendly.com/jurifyai/30min');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const preventDefault = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <header className={styles.navigation}>
      <button className={styles.button} onClick={preventDefault}>
        <div className={styles.joinNow} onClick={openCalendly}>
          Join
        </div>
      </button>

      {isMobileMenuOpen && (
        <div className={styles.mobileOverlay} onClick={closeMobileMenu}>
          <div className={styles.mobileMenu}>
            {/* Close button with animation */}
            <div className={styles.closeButton} onClick={closeMobileMenu}>
              &times;
            </div>

            {/* Logo at the start of the mobile menu */}
            <div className={styles.logo}>
              <img
                className={styles.symbolIcon}
                loading="eager"
                alt=""
                src="/symbol@2x.png" // Add the correct path for your logo
              />
            </div>

            {/* Docs link with animation */}
            <a
              href="https://docs.jurifyai.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.menuItem}
              onClick={closeMobileMenu}
            >
              Docs
            </a>

            {/* Request Access link with animation */}
            <div
              className={styles.menuItem}
              onClick={() => {
                openCalendly();
                closeMobileMenu();
              }}
            >
              Request Access
            </div>
          </div>
        </div>
      )}

      <div className={styles.logo}>
        <img
          className={styles.symbolIcon}
          loading="eager"
          alt=""
          src="/symbol1@2x.png"
        />
        <img
          className={styles.image363Icon}
          loading="eager"
          alt=""
          src="/image-3631@2x.png"
        />
      </div>
      <img
        className={styles.icon}
        loading="eager"
        alt=""
        src="/icon.svg"
        onClick={toggleMobileMenu}
      />
    </header>
  );
};

export default Navigation1;
