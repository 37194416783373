import React, { FunctionComponent } from "react";
import styles from "./FRAME.module.css";

const FRAME: FunctionComponent = () => {
  // Function to open Calendly scheduling widget
  const openCalendly = () => {
    window.Calendly.showPopupWidget('https://calendly.com/jurifyai/30min');
  };
  
  return (
    <section className={styles.fRAME}>
      <div className={styles.heroSection}>
        <div className={styles.heroContainer}>
          <div className={styles.contentGrid}>
            <div className={styles.wrapper}>
              <h3 className={styles.heading1}>
                AI-powered legal research solution
              </h3>
              <h1 className={styles.display1}>
                <span className={styles.empoweringYourLegal}>
                  {`Empowering your legal `}
                </span>
                <span className={styles.operations}>operations</span>
                <span className={styles.span}>.</span>
              </h1>
            </div>
            <div className={styles.textBlock}>
              Effortlessly discover court judgments, legislative proposals,
              government decisions, and legal documents with speed and
              precision.
            </div>
            <div className={styles.actions}>
              {/* Use an anchor tag to open the specified URL */}
              <a
                href="https://docs.jurifyai.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button}
              >
                <b className={styles.learnMore}>Learn More</b>
              </a>
              {/* Use the onClick handler to trigger the openCalendly function */}
              <button className={styles.button1} onClick={openCalendly}>
                <img className={styles.playIcon} alt="" src="/play.svg" />
                <b className={styles.scheduleDemo}>Schedule Demo</b>
              </button>
            </div>
          </div>
          <div className={styles.cardWrapper}>
            <img
              className={styles.cardCircleIcon}
              alt=""
              src="/card-circle.svg"
            />
            <div className={styles.cardItem}>
              <div className={styles.logoWrapper}>
                <img className={styles.aetnaIcon} alt="" src="/aetna.svg" />
              </div>
              <div className={styles.contentWrapper}>
                <div className={styles.header}>
                  <b className={styles.anthemBlueCross}>Anthem Blue Cross</b>
                  <div className={styles.smbGlobalPlan}>SMB Global Plan</div>
                </div>
                <div className={styles.matchDetails}>
                  <div className={styles.matchTag}>
                    <b className={styles.matchValue}>91%</b>
                  </div>
                  <div className={styles.planMatchForContainer}>
                    <b>Plan Match</b>
                    <span className={styles.forBloomtech}>
                      {` for BloomTech `}
                    </span>
                  </div>
                </div>
              </div>
              <img
                className={styles.arrowlineuprightIcon}
                alt=""
                src="/arrowlineupright.svg"
              />
            </div>
          </div>
        </div>

        <video
          className={styles.chat1Icon}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="/gif-home.WEBM" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>

      <header className={styles.navigation}>
        <div className={styles.navContainer}>
          <div className={styles.leftColumn}>
            <div className={styles.logo}>
              <img
                className={styles.symbolIcon}
                loading="eager"
                alt=""
                src="/symbol@2x.png"
              />
              <img
                className={styles.image363Icon}
                loading="eager"
                alt=""
                src="/image-363@2x.png"
              />
            </div>
          </div>
          <div className={styles.rightColumn}>
            <div className={styles.navLinks}>
              {/* Use an anchor tag to open the specified URL */}
              <a
                href="https://docs.jurifyai.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.docs}
              >
                Docs
              </a>
              <div className={styles.navLink1} onClick={openCalendly}>
                <div className={styles.docs}>Request Access</div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
};

export default FRAME;
