import React, { FunctionComponent } from "react";
import styles from "./LogosGroup.module.css";

const LogosGroup: FunctionComponent = () => {
  return (
    <div className={styles.logosGroup}>
      <div className={styles.logoItem}>
      <a
              href="https://www.jurifyai.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
        <img
          className={styles.image363Icon}
          alt=""
          src="/image-363-11@2x.png"
        />
        </a>
      </div>
      <div className={styles.logoItem1}>
        <div className={styles.image2073Parent}>
        <a
              href="mailto:dzaburof@jurifyai.com"
              target="_blank"
              rel="noopener noreferrer"
            >
          <img
            className={styles.image2073Icon}
            loading="eager"
            alt=""
            src="/image-20731@2x.png"
          />
          </a>
          <a
              href="https://twitter.com/JurifyAI"
              target="_blank"
              rel="noopener noreferrer"
            >
          <img
            className={styles.image2074Icon}
            loading="eager"
            alt=""
            src="/image-2074@2x.png"
          />
          </a>
        </div>
        <a
              href="https://www.linkedin.com/in/daniel-zaburof/"
              target="_blank"
              rel="noopener noreferrer"
            >
        <img
          className={styles.image2075Icon}
          loading="eager"
          alt=""
          src="/image-2075@2x.png"
        />
        </a>
      </div>
    </div>
  );
};

export default LogosGroup;
