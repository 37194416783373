import React, { FunctionComponent } from "react";
import styles from "./FrameComponent.module.css";

const FrameComponent: FunctionComponent = () => {
  // Function to open Calendly scheduling widget
  const openCalendly = () => {
    window.Calendly.showPopupWidget('https://calendly.com/jurifyai/30min');
  };
    
  return (
    <div className={styles.containerParent}>
      <div className={styles.container}>
        <div className={styles.logoItem}>
          <div className={styles.display1Wrapper}>
            <a
              href="https://docs.jurifyai.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.button}
            >
              <h3 className={styles.display12}>Docs</h3>
            </a>
          </div>
          <div className={styles.display1Wrapper}>
            {/* Use the onClick handler to trigger the openCalendly function */}
            <div className={styles.button} onClick={openCalendly}>
              <h3 className={styles.display12}>Demo</h3>
            </div>
          </div>
        </div>
        <div className={styles.logoItemDisplayWrapper}>
          <div className={styles.logoItemDisplay}>
            <div className={styles.logoItem2}>
              <div className={styles.logoItem3}>
                <div className={styles.display3}>
              <a
                href="https://docs.jurifyai.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.display31}
              >
                Overview
              </a>
            </div>
                <div className={styles.display6}>
              <a
                href="https://docs.jurifyai.com/fundamentals/prompt-and-label"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.display31}
              >
                Prompt & Label
              </a>
            </div>
                <div className={styles.display8}>
              <a
                href="https://docs.jurifyai.com/fundamentals/team"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.display31}
              >
                Team
              </a>
            </div>
                <div className={styles.display4}>
              <a
                href="https://docs.jurifyai.com/fundamentals/vision"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.display31}
              >
                Vision
              </a>
            </div>
                <div className={styles.display7}>
              <a
                href="https://docs.jurifyai.com/fundamentals/terms-and-policies"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.display31}
              >
                Terms & policies
              </a>
            </div>
              </div>
            </div>
            <div className={styles.logoItem4}>
              <div className={styles.logoItemText}>
                {/* Use the onClick handler to trigger the openCalendly function */}
                <div className={styles.display31} onClick={openCalendly}>
                  Schedule
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
