import React, { FunctionComponent } from "react";
import Navigation1 from "../components/Navigation1";
import HeroSection from "../components/HeroSection";
import FrameComponent from "../components/FrameComponent";
import LogosGroup from "../components/LogosGroup";
import styles from "./IPhone1314.module.css";

const IPhone1314: FunctionComponent = () => {
  return (
    <div className={styles.mobile}>
              <section className={styles.navigationParent}>
                <Navigation1 />
        <div className={styles.chat1Parent}>
                  <img
            className={styles.chat1Icon}
            src="/gif-home-jury.gif"
            alt="Chatting GIF"
          />
          <HeroSection />
        </div>
      </section>
      <footer className={styles.section}>
        <FrameComponent />
        <LogosGroup />
      </footer>
    </div>
  );
};

export default IPhone1314;
