// Assuming openCalendly is implemented in a file named calendly.ts
import React, { FunctionComponent } from "react";
import styles from "./SectionContainer.module.css";

  // Function to open Calendly scheduling widget
  const openCalendly = () => {
    window.Calendly.showPopupWidget('https://calendly.com/jurifyai/30min');
  };

const SectionContainer: FunctionComponent = () => {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.logosGroup}>
        <div className={styles.logoItem}>
          <img
            className={styles.image363Icon}
            alt=""
            src="/image-363-1@2x.png"
          />
          <b className={styles.display1}>Docs</b>
        </div>
        <div className={styles.logoItem1}>
          <div className={styles.logoItem2}>
            <div className={styles.display6Wrapper}>
              <a
                href="https://docs.jurifyai.com/fundamentals/prompt-and-label"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button}
              >
                Prompt & Label
              </a>
            </div>
            <div className={styles.display8Wrapper}>
              <a
                href="https://docs.jurifyai.com/fundamentals/team"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button}
              >
                Team
              </a>
            </div>
            <div className={styles.display8Wrapper}>
              <a
                href="https://docs.jurifyai.com/fundamentals/vision"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button}
              >
                Vision
              </a>
            </div>
            <div className={styles.display8Wrapper}>
              <a
                href="https://docs.jurifyai.com/fundamentals/terms-and-policies"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.button}
              >
                Terms & policies
              </a>
            </div>
          </div>
        </div>
        <div className={styles.logoItem3}>
          <div className={styles.display8Wrapper}>
            <a
              href="https://www.linkedin.com/in/daniel-zaburof/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.button}
            >
              Linkedin
            </a>
          </div>
          <div className={styles.displayTextFrame}>
            <a
              href="https://twitter.com/JurifyAI"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.image2073Icon}
                loading="eager"
                alt=""
                src="/image-2073@2x.png"
              />
            </a>
            <div className={styles.frameContainer}>
              <div className={styles.display8Wrapper}>
                <a
                  href="https://wa.me/972543451996"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.button}
                >
                  Phone
                </a>
              </div>
            </div>
          </div>
          <div className={styles.display8Wrapper}>
            <a
              href="mailto:dzaburof@jurifyai.com"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.button}
            >
              Email
            </a>
          </div>
        </div>
        <div className={styles.logoItem4}>
          <div className={styles.frameContainer1}>
            {/* Use the onClick handler to trigger the openCalendly function */}
            <div className={styles.display32} onClick={openCalendly}>
              Schedule
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionContainer;
