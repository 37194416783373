import React, { FunctionComponent } from "react";
import styles from "./Container.module.css";

const Container: FunctionComponent = () => {
  // Function to open Calendly scheduling widget
  const openCalendly = () => {
    window.Calendly.showPopupWidget('https://calendly.com/jurifyai/30min');
  };

  return (
    <div className={styles.container}>
      <div className={styles.logosGroup}>
        <div className={styles.logoItem}>
          <img
            className={styles.image363Icon}
            loading="eager"
            alt=""
            src="/jurify-logo.png"
          />
        </div>
        <div className={styles.display1Wrapper}>
          <a
            href="https://docs.jurifyai.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.button}
          >
            <h3 className={styles.display12}>Docs</h3>
          </a>
        </div>
        <div className={styles.display1Wrapper}>
          <a
            href="mailto:dzaburof@jurifyai.com"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.button}
          >
            <h3 className={styles.display12}>Contact</h3>
          </a>
        </div>
        <div className={styles.display1Wrapper}>
          {/* Use the onClick handler to trigger the openCalendly function */}
          <div className={styles.button} onClick={openCalendly}>
            <h3 className={styles.display12}>Demo</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
