import React, { FunctionComponent } from "react";
import FRAME from "../components/FRAME";
import Container from "../components/Container";
import SectionContainer from "../components/SectionContainer";
import styles from "./Desktop.module.css";

const Desktop: FunctionComponent = () => {
  return (
    <div className={styles.desktop2}>
      <FRAME />
      <footer className={styles.section}>
        <Container />
        <SectionContainer />
      </footer>
    </div>
  );
};

export default Desktop;
